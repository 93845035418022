import React, { useEffect, useRef } from 'react';
// import NaegiftKoLogo11 from "../images/naegift_ko_logo_1.png"


export default function AgreementWrap(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <h3>제 1장 총칙</h3>
        <h4>제 1 조 (목적)</h4>
        <ol>
            <li>이 이용약관은 "GDWEB (이하 "당 사이트")"에서 제공하는 인터넷 서비스(이하 '서비스')의 가입조건, 당 사이트와 이용자의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.</li>
        </ol>
        <h4>제 2 조 (용어의 정의)</h4>
        <ol>
            <li>"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
            <li>"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.</li>
            <li>"비회원"이하 함은 회원으로 가입하지 않고 "GDWEB"에서 제공하는 서비스를 이용하는 자를 말합니다.</li>
        </ol>
        <h4>제 3 조 (이용약관의 효력 및 변경)</h4>
        <ol>
            <li>"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
            <li>"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.</li>
            <li>"비회원"이하 함은 회원으로 가입하지 않고 "GDWEB"에서 제공하는 서비스를 이용하는 자를 말합니다.</li>
        </ol>

        <h3>제 2장 총칙</h3>
        <h4>제 1 조 (목적)</h4>
        <ol>
            <li>이 이용약관은 "GDWEB (이하 "당 사이트")"에서 제공하는 인터넷 서비스(이하 '서비스')의 가입조건, 당 사이트와 이용자의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.</li>
        </ol>
    </>
    );
}