import React, { useEffect, useRef } from 'react';
import { Config } from "../../common/config";


export default function Agreement(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>서비스이용약관</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_02">
                <h2 className="tit_01">서비스이용약관</h2>

                <article className="contants_box_02 agreement_box">

                    <iframe src={`${Config.SHOPADMIN_SERVER}/agreement_wrap.html`}></iframe>

                </article>

            </section>
        </main>
    </>
    );
}