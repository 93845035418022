import React, { useEffect, useRef } from 'react';
// import NaegiftKoLogo11 from "../images/naegift_ko_logo_1.png"


export default function PrivacyWrap(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <h3>개인정보 수집에 대한 동의</h3>
        <ol>
            <li>GDWEB은 귀하께 회원가입시 개인정보보호방침 또는 이용약관의 내용을 공지하며 회원가입버튼을 클릭하면 개인정보 수집에 대해 동의하신 것으로 봅니다.</li>
        </ol>

        <h3>개인정보의 수집목적 및 이용목적</h3>
        <h4>GDWEB은 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다 .</h4>
        <ol>
            <li>"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
            <li>"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.</li>
            <li>"비회원"이하 함은 회원으로 가입하지 않고 "GDWEB"에서 제공하는 서비스를 이용하는 자를 말합니다.</li>
        </ol>
        
        <h3>개인정보의 수집목적 및 이용목적</h3>
        <h4>GDWEB은 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다 .</h4>
        <ol>
            <li>"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
            <li>"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.</li>
            <li>"비회원"이하 함은 회원으로 가입하지 않고 "GDWEB"에서 제공하는 서비스를 이용하는 자를 말합니다.</li>
        </ol>
        <h3>개인정보의 수집목적 및 이용목적</h3>
        <h4>GDWEB은 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다 .</h4>
        <ol>
            <li>"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
            <li>"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.</li>
            <li>"비회원"이하 함은 회원으로 가입하지 않고 "GDWEB"에서 제공하는 서비스를 이용하는 자를 말합니다.</li>
        </ol>
    </>
    );
}