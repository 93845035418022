import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { unsetToken, userLogin, userLogout } from "../../reducers/userInfo";
import AlertModal from '../../components/alertModal';
import { Config } from "../../common/config";
import axios from "axios";


export default function Login(props) {
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});
    const userInfo = useSelector((state) => state.userInfo);
    const [id, setId] = useState(userInfo.id? userInfo.id : '');
    const [password, setPassword] = useState(userInfo.pwd? userInfo.pwd : '');
    const [isChecked, setIsChecked] = useState(userInfo.id? true : false); 

    const openModal = (e) => {
        // console.log("openModal actionFlag:"+modalInfo.actionFlag);
            // if(modalInfo.actionFlag === 'del'){
            //     // console.log("===== del");
            //     setModalInfo({...modalInfo, actionFlag:'del', mainMsg:'정말 삭제하시겠습니까?\n기프트가 판매된 경우는\n삭제가 불가능합니다.'});
            // }else if(modalInfo.actionFlag === 'mod'){
            //     // console.log("===== mod");
            //     setModalInfo({...modalInfo, actionFlag:'mod', mainMsg:'정말 수정하시겠습니까?'});
            // }
            setModalOpen(true);
    };
     
    const closeModal = () => {
         setModalOpen(false);
        //  if(modalInfo.actionFlag === 'list'){
        //    getData();   
        // } 
    };
 
    const doModal = async () => {
        // console.log("doModal actionFlag:"+modalInfo.actionFlag);
        setModalOpen(false);
    };

    const checkboxChange = (e) => {
        setIsChecked(!isChecked);
    };
    
    const checkUserData = () => {
        // console.log("checkUserData");
        // console.log("id : " + id);
        // console.log("pwd : " + password);
// return true;
        if(!id)
        {
            modalInfo.mainMsg = '사용자 아이디를 입력하세요.';
            return false;
        }
        if(!password){
           modalInfo.mainMsg = '비밀번호를 입력하세요.';
            return false;
        }
        
        return true;      
    }

    const goLogin = async () => {     
        try{
            if(userInfo.id){
                login();
            }else {
               if (checkUserData()) {
                    login();
                }else{
                    setModalInfo({...modalInfo}); 
                    setModalOpen(true);
                } 
            }  
        }catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            // console.log("code : "+JSON.stringify(error.response.data.resultCode));     
            // console.error(error.message);
            // console.error(error.stack);      
            if(error.response.data.resultCode === 404) {
                dispatch(userLogout());
                modalInfo.mainMsg = "아이디 또는 비밀번호가 일치하지 않거나\n회원탈퇴된 아이디입니다.\n(비밀번호는 대소문자 구분됩니다)";
                setModalOpen(true);
            }
        }   
    }

    const login = async () => {     
        try{
            // console.log("login");
            // console.log(userInfo.id);
            // console.log(id);

            if(id != ''){
                if(userInfo.id && userInfo.id === id && modalInfo.actionFlag !== 'getToken'){
                    // console.log("userInfo.jwtToken : "+userInfo.jwtToken);
                    const ret = await axios.get(`${Config.API_SERVER}auth/authTruthCheck`,
                                { headers: { 
                                    Authorization: "Bearer "+userInfo.jwtToken,
                                    },
                                }
                            );

                    // console.log("Data : "+JSON.stringify(ret.data));

                    goShopAdmin(userInfo.jwtToken);

                }else{
                     const userData = {userId: (userInfo.id === id &&  modalInfo.actionFlag==='getToken') ? userInfo.id : id, 
                        pwd:(userInfo.id === id &&  modalInfo.actionFlag==='getToken') ? userInfo.pwd: password};
                    // console.log(JSON.stringify(userData));
                    const ret1 = await axios.post(`${Config.API_SERVER}auth/getuser`,
                        userData,               
                    );

                    // console.log("Data : "+JSON.stringify(ret1.data));
                    console.log("Data : "+JSON.stringify(ret1.data.data));

                    if(ret1?.data?.data?.accessToken){

                        if(isChecked) {
                            // 로그인 유지함: userId, pwd 저장
                            const userLoginData = {
                                id: userData.userId,
                                pwd: userData.pwd,
                                jwtToken: ret1?.data?.data?.accessToken  
                                };  

                            // const userLoginData = {
                            //     id: 'caffeL4',
                            //     pwd: '1234',
                            //     jwtToken: ret1?.data?.data?.accessToken  
                            //     };                  
                            //   console.log("===== userLoginData : "+JSON.stringify(userLoginData));
                            dispatch(userLogin(userLoginData));
                        }else{
                            // 로그인 유지 안함: 기존에 저장된게 있다면 userId, pwd 삭제
                            if(userInfo.id){
                                dispatch(userLogout());
                            }
                        }

                        goShopAdmin(ret1?.data?.data.accessToken);
        
                }  
            }
        }
        }catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));     
            if(error.response.data.resultCode === 401) {
                if(userInfo.id){
                    dispatch(unsetToken());
                    modalInfo.actionFlag = 'getToken';
                }

                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);

            }else  if(error.response.data.resultCode === 404) {
                modalInfo.mainMsg = "아이디 또는 비밀번호가 일치하지 않거나\n회원탈퇴된 아이디입니다.\n(비밀번호는 대소문자 구분됩니다)";
                setModalOpen(true);
            }

        }   
    }   

    const goShopAdmin = async (jwtToken) => {    

        // JSON 문자열 직렬화
        // const jsonData = JSON.stringify(user);
        const jsonData = JSON.stringify(jwtToken);
        // Base64 인코딩
        const encodedData = jsonData;
            // URL 파라미터 생성
        const params = new URLSearchParams();
        params.set('data', encodeURIComponent(encodedData));

        // URL 생성
        const url = `${Config.SHOPADMIN_SERVER}login?${params.toString()}`;

        // URL 이동
        window.location.href = url;
    }

    useEffect(() => {

        if(userInfo.id) {
            // console.log("id");
            login();
        }
    }, [userInfo.id]);
  
    return (
    <>
        <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
            {modalInfo.mainMsg}
        </AlertModal>

        {/* {!userInfo.jwtToken && ( */}
        <>    
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>관리자 로그인</li>
            </ul>
        </section>

        <main>
            <section className="contants_box_04">

                <article className="signup_07">
                    <h2 className="tit_01">관리자 로그인</h2>
                </article>

                <article className="contants_box_03">
                    <table className="login_box">
                    <tbody>

                    {/* <article className="button_box_01">
                        <NavLink to='/test'>
                            <button type="button" className="button_01 button_06 button_gray_line_bg">TEST</button>
                        </NavLink>
                    </article> */}

                        {/* {userInfo.id ? 
                            <>
                                <tr>
                                    <th>아이디</th>
                                    <td><input className="input_03" type="text" name="" style={{backgroundColor:  '#ddd'}}                                     
                                         onChange={e => {
                                            setId(e.target.value);
                                        }} 
                                        value = {id} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td><input className="input_03" type="password" name="" style={{backgroundColor:  '#ddd'}} 
                                        onChange={e => {
                                            setPassword(e.target.value);
                                        }} 
                                        value = {password} />
                                    </td>
                                </tr>
                            </>
                            : */}
                            <>
                                <tr>
                                    <th>아이디</th>
                                    <td><input className="input_03" type="text" name="" 
                                        onChange={e => {
                                            setId(e.target.value);
                                        }} 
                                        value = {id} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td><input className="input_03" type="password" name="" 
                                        onChange={e => {
                                            setPassword(e.target.value);
                                        }} 
                                        value = {password} />
                                    </td>
                                </tr>
                            </>
                        {/* } */}
                        </tbody>
                    </table>
                </article>

                <div className="signup_08">
                    <label className="check_box">로그인유지
                        <input type="checkbox" checked={isChecked} onChange={checkboxChange} />
                        <span className="check_check"></span>
                    </label>

                    <article className="button_box_01">
                        <button type="button" className="button_01 button_06 button_red" onClick={goLogin}>로그인</button>
                    </article>
                    <article className="button_box_01">
                        <NavLink to='/login-idsearch'>
                            <br />
                            <button type="button" className="button_01 button_07 button_gray_line">아이디 찾기</button>
                        </NavLink>
                        <NavLink to='/login-pwdsearch'>
                            <br />
                            <button type="button" className="button_01 button_07 button_gray_line">비밀번호 찾기</button>
                        </NavLink>
                    </article>
                    <article className="button_box_01">
                        <NavLink to='/user-signup'>
                            <button type="button" className="button_01 button_06 button_gray_line_bg">회원가입</button>
                        </NavLink>
                    </article>
                </div>
                
            </section>
        </main>
        </>
        {/* )} */}
    </>
    );
}