import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { userLogin, userLogout } from "../reducers/userInfo";
import { setLanguage } from "../reducers/languageSlice"; // Import the language slice action
import { translations } from "../data/translation"; // Import the translations
import naegift_ko_logo from "../images/naegift_ko_logo.png";
import naegift_eng_logo from "../images/naegift_eng_logo.png";

export default function Header() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const language = useSelector((state) => state.language.language); // Get current language from Redux store
    const navigate = useNavigate();

    const logOut = () => {
        dispatch(userLogout());
        goLogout();
        // navigate('/')
    }

    const handleLoginClick = (e) => {
        e.preventDefault();
        window.location.href = 'https://shopadmin-test.naegift.com/login';
    };

    const goLogout = async () => {
        try {
            // Logout logic with API call can be added here.
        } catch (error) {
            console.error("Error User Logout", error);
        }
    }

    const toggleLanguage = () => {
        const newLanguage = language === 'ko' ? 'en' : 'ko';
        dispatch(setLanguage(newLanguage));
    };

    useEffect(() => {
        // Side effects on header load
    }, []);

    const t = translations[language]; // Get the translation object based on the selected language

    return (
        <>
            <header>
                <section className="header_box">
                    <h1>
                        <NavLink to='/'><img className="header_logo" src={language ==="en" ? naegift_eng_logo : naegift_ko_logo} alt={t.logoAlt} /></NavLink>
                    </h1>
                    <nav>
                        <ul className="header_nav">
                            <li>
                                <NavLink className="select" to='/'>{t.serviceIntro}</NavLink>
                            </li>
                            <li><a href="">{t.storeSupport}</a></li>
                        </ul>
                    </nav>
                    <ul className="header_login">
                  
                        {userInfo?.id ?
                            <>
                                <li><NavLink to='/' onClick={logOut}>{t.logout}</NavLink></li>
                                <li><NavLink to='/gift-list'>{t.myPage}</NavLink></li>
                            </>
                            :
                            <>
                                <li><NavLink onClick={handleLoginClick}>{t.login}</NavLink></li>
                                <li><NavLink to='/user-signup'>{t.signup}</NavLink></li>
                            </>
                        }
                          <li className="language-toggle" onClick={toggleLanguage}>
                        {language === 'ko' ? 'EN' : 'KO'}
                    </li>
                    </ul>
                   
                </section>
            </header>
        </>
    );
}
