export const Config = {
    API_SERVER: process.env.REACT_APP_API_SERVER,
    SHOPADMIN_SERVER: process.env.REACT_APP_SHOPADMIN_SERVER,
    SHOPUSER_SERVER: process.env.REACT_APP_SHOPUSER_SERVER,
    // ADDRESS_SRC: '/t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
    PAGE_SIZE_LIST: [10, 50, 100, 200],
    PB_VALUES: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    PB_INTERVAL: 300,        // PGOGRESSBAR INTERVAL (1000=1초)
    // BOARD_SECTION: {CODE: {ALL:'all', NOTICE: 'BD01', NEWS: 'BD02', BD03: 'question'},
    //                 TITLE: {ALL:'전체', NOTICE: '공지사항', NEWS: '최신뉴스', QUESTION: '자주묻는질문'}},
    // TAG_SECTION: {CODE: {ALL:'all', TG01: 'TG01', TG02: 'TG02', TG03: 'TG03', TG04: 'TG04', TG05: 'TG05', TG06: 'TG06'},
    //                 TITLE: {ALL:'전체', TG01: '복권구매', TG02: '이용안내', TG03: '캐시', TG04: '마일리지/포인트/쿠폰',
    //                  TG05: '당첨금', TG06: '기타'}},
    GAME_SECTION: {CODE: {POWERBALL: 'LT01', MEGAMILLION: 'LT02'},
                    TITLE: {POWERBALL: '파워볼', MEGAMILLION: '메가밀리언'}},
    USER_SECTION: {CODE: {ALL:'all', ID: 'id', NAME: 'name', MOBILE: 'mobile', EMAIL: 'email'},
                    TITLE: {ALL:'전체', ID: 'ID', NAME: '이름', MOBILE: '전화번호', EMAIL: 'E-Mail'}},

  };
  