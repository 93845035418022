import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Config } from "../../common/config";
import axios from "axios";
import AlertModal from "../../components/alertModal";
import { getPostcode } from "../../components/zip";

export default function UserContent(props) {
  var script = document.createElement("script");
  script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  // script.src = Config.ADDRESS_SRC;
  document.head.appendChild(script);

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false); //모달 상태 관리 : 기본값 - 닫힘
  const [modalInfo, setModalInfo] = useState({
    isCancel: false,
    isConfirm: true,
    isMessage: true,
    headerMsg: "",
    actionFlag: "",
    mainMsg: "",
    action: "",
    isPercent: false,
  });
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("M");
  const [emailAuthNo, setEmailAuthNo] = useState(0);
  const [sms1, setSms1] = useState("");
  const [sms2, setSms2] = useState("");
  const [sms3, setSms3] = useState("");
  const [mobileAuthNo, setMobileAuthNo] = useState(0);
  const [verifyId, setVerifyId] = useState(false);
  const [verifySamePassword, setVerifySamePassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifySMS, setVerifySMS] = useState(false);
  const [acceptAgree, setAcceptAgree] = useState("RA01");
  // const [zipCode, setzipCode] = useState('');
  // const [addr, setAddr] = useState('');
  const [detailAddr, setDetailAddr] = useState("");
  let userData = {};
  let totalUserData = {};

  const openModal = (e) => {
    if (modalInfo.actionFlag === "id1") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "아이디는 영문 소문자, 숫자를 포함한 5~15자 이내로 해주세요.",
      });
    } else if (modalInfo.actionFlag === "id2") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "중복된 아이디 입니다. 다른 아이디를 넣어주세요",
      });
    } else if (modalInfo.actionFlag === "verifyId") {
      setModalInfo({ ...modalInfo, mainMsg: "아이디 중복확인 해주세요." });
    } else if (modalInfo.actionFlag === "password") {
      setModalInfo({
        ...modalInfo,
        mainMsg: `비밀번호는 영문과 숫자 또는 특수문자 조합 8~16자이내로\n입력하세요. 개인정보가 포함되거나 4자이상 연속된 동일한\n문자 또는 숫자는 비밀번호로 사용이 불가합니다.`,
      });
    } else if (modalInfo.actionFlag === "verifySamePassword") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "비밀번호가 일치하지 않습니다(대소문자 구분).",
      });
    } else if (modalInfo.actionFlag === "mail") {
      setModalInfo({ ...modalInfo, mainMsg: `메일을 보내는 중입니다.....` });
    } else if (modalInfo.actionFlag === "getEmailAuth") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "이메일 인증번호받기를 해주세요.",
      });
    } else if (modalInfo.actionFlag === "verifyEmailError") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "이메일 인증번호가 다릅니다. 다시 해주세요",
      });
    } else if (modalInfo.actionFlag === "verifyEmail") {
      setModalInfo({ ...modalInfo, mainMsg: "이메일 인증 해주세요." });
    } else if (modalInfo.actionFlag === "SMS") {
      setModalInfo({ ...modalInfo, mainMsg: `SMS를 보내는 중입니다.....` });
    } else if (modalInfo.actionFlag === "getSMSAuth") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "휴대폰 인증번호받기를 해주세요.",
      });
    } else if (modalInfo.actionFlag === "verifySMSError") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "휴대폰 인증번호가 다릅니다. 다시 해주세요",
      });
    } else if (modalInfo.actionFlag === "verifySMS") {
      setModalInfo({ ...modalInfo, mainMsg: "휴대폰 번호 인증 해주세요." });
    } else if (modalInfo.actionFlag === "api") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "API 서버에러입니다. 잠시 후 다시 시도해주세요.",
      });
    } else if (modalInfo.actionFlag === "name") {
      setModalInfo({ ...modalInfo, mainMsg: "이름을 입력해 주세요." });
    } else if (modalInfo.actionFlag === "email") {
      setModalInfo({ ...modalInfo, mainMsg: "이메일을 입력해 주세요." });
    }

    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    //  if(modalInfo.actionFlag === 'list'){
    //    getData();
    // }
  };

  const doModal = () => {
    // console.log("doModal actionFlag:"+modalInfo.actionFlag);
    setModalOpen(false);
    // if(modalInfo.actionFlag === 'mod'){
    //     doModModal();
    // }else if(modalInfo.actionFlag === 'pwd'){
    //     // 바뀐 비밀번호값 넣기
    //     modalInfo.isMessage = true;
    // }
  };

  const doModModal = () => {
    // console.log("doModModal");
    // console.log("selectedId : "+selectedId);
    // modUser();
  };

  const validateId = () => {
    // console.log(id);
    // console.log(id.length);
    // 비밀번호의 길이가 5에서 15자 사이인지 확인
    if (id.length === 0 || id.length < 5 || id.length > 15) {
      modalInfo.actionFlag = "id1";
      openModal();
    }

    // 영문소문자, 숫자 중 2가지 이상을 포함하는지 확인
    let hasLowerCase = false;
    let hasNumber = false;

    for (let i = 0; i < id.length; i++) {
      const char = id.charAt(i);

      if (char >= "a" && char <= "z") {
        hasLowerCase = true;
      } else if (char >= "0" && char <= "9") {
        hasNumber = true;
      }
    }

    const conditions = [hasLowerCase, hasNumber];
    const countTrue = conditions.filter((condition) => condition).length;

    // console.log(countTrue);
    if (countTrue < 2) {
      modalInfo.actionFlag = "id1";
      openModal();
    } else {
      return true;
    }
  };

  const validatePassword = () => {
    // console.log(password);
    // console.log(password.length);
    // 비밀번호의 길이가 8에서 16자 사이인지 확인
    if (password.length === 0 || password.length < 8 || password.length > 16) {
      // console.log("1");
      modalInfo.actionFlag = "password";
      openModal();
    } else {
      // 영문, 숫자, 특수문자 중 2가지 이상을 포함하는지 확인
      let hasUpperCase = false;
      let hasLowerCase = false;
      let hasNumber = false;
      let hasSpecialChar = false;

      const specialChars = "!\"#$%&'()*+,-./:;>=<?@[\\]^_`{|}~";

      for (let i = 0; i < password.length; i++) {
        const char = password.charAt(i);

        if (char >= "a" && char <= "z") {
          hasLowerCase = true;
        } else if (char >= "A" && char <= "Z") {
          hasUpperCase = true;
        } else if (char >= "0" && char <= "9") {
          hasNumber = true;
        } else if (specialChars.includes(char)) {
          hasSpecialChar = true;
        }
      }

      const conditions = [
        hasUpperCase,
        hasLowerCase,
        hasNumber,
        hasSpecialChar,
      ];
      const countTrue = conditions.filter((condition) => condition).length;
      // console.log("2 : "+countTrue);
      // console.log("2 : hasUpperCase "+hasUpperCase);
      // console.log("2 : hasLowerCase "+hasLowerCase);
      // console.log("2 : hasNumber "+hasNumber);
      // console.log("2 : hasSpecialChar "+hasSpecialChar);

      if (countTrue < 2) {
        modalInfo.actionFlag = "password";
        openModal();
      } else {
        // 동일한 문자가 4번 이상 연속으로 나오는지 확인
        for (let i = 0; i < password.length - 3; i++) {
          if (
            password[i] === password[i + 1] &&
            password[i] === password[i + 2] &&
            password[i] === password[i + 3]
          ) {
            modalInfo.actionFlag = "password";
            openModal();
          }
        }
      }
    }
  };

  const validateSamePassword = (pwd) => {
    // console.log(password);
    // console.log(pwd);

    if (password !== pwd) {
      modalInfo.actionFlag = "verifySamePassword";
      openModal();
    } else {
      setVerifySamePassword(true);
    }
  };

  const idCkeck = async () => {
    // console.log("idCkeck")

    const result = validateId();
    if (result) {
      // console.log("true");
      const data = { userId: id };
      // console.log(Config.API_SERVER);
      const ret = await axios.post(`${Config.API_SERVER}user/idCheck`, data);
      // console.log(JSON.stringify(ret));
      // console.log(ret.data.data.dupResult);
      if (ret.data.data.dupResult) {
        modalInfo.actionFlag = "id2";
        openModal();
      } else {
        setVerifyId(true);
        setModalInfo({ ...modalInfo, mainMsg: "사용할 수 있는 아이디입니다." });
        setModalOpen(true);
      }
    } else {
      //    console.log("false");
    }
  };

  const getEmailAuthNo = async () => {
    // console.log('getEmailAuthNo');

    if (verifyId) {
      if (verifySamePassword) {
        if (name && email) {
          modalInfo.actionFlag = "mail";
          modalInfo.isConfirm = false;
          openModal();
          const data = { userId: id, userName: name, pwd: password, email };
          // console.log("data : "+JSON.stringify(data));
          const ret = await axios.post(
            `${Config.API_SERVER}user/sendEmail`,
            data
          );
          // console.log(JSON.stringify(ret.data));
          if (ret.data.data.emailAuthNo) {
            setEmailAuthNo(ret.data.data.emailAuthNo);
            setModalOpen(false);
            setModalInfo({
              ...modalInfo,
              isConfirm: true,
              mainMsg: "인증번호를 메일에서 확인하고 넣어주세요!",
            });
            setModalOpen(true);
          } else {
            // API 에러...
            setModalOpen(false);
            modalInfo.actionFlag = "api";
            modalInfo.isConfirm = true;
            openModal();
          }
        } else {
          if (!name) {
            modalInfo.actionFlag = "name";
            openModal();
          } else if (!email) {
            modalInfo.actionFlag = "email";
            openModal();
          }
        }
      } else {
        modalInfo.actionFlag = "verifySamePassword";
        openModal();
      }
    } else {
      modalInfo.actionFlag = "verifyId";
      openModal();
    }
  };

  const validateEmail = () => {
    // 빈 문자열 검사
    if (!email) {
      modalInfo.mainMsg = "이메일 주소를 입력하세요.";
      setModalOpen(true);
    }

    // 문자열 형식 검사
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(email)) {
      modalInfo.mainMsg = "이메일 주소가 유효하지 않습니다.";
      setModalOpen(true);
    }
  };

  const validateEmailAuthNo = async () => {
    if (emailAuthNo > 0) {
      const data = { userId: id, emailAuthNo };
      // console.log("data : "+JSON.stringify(data));
      const ret = await axios.post(
        `${Config.API_SERVER}user/verifyEmail`,
        data
      );
      // console.log(JSON.stringify(ret));
      if (ret?.data?.data?.verifyResult) {
        setVerifyEmail(true);
        setModalInfo({
          ...modalInfo,
          mainMsg: "이메일 인증번호가 확인 되었습니다.",
        });
        setModalOpen(true);
      } else {
        // API 에러...
        modalInfo.actionFlag = "verifyEmailError";
        openModal();
      }
    } else {
      modalInfo.actionFlag = "getEmailAuth";
      openModal();
    }
  };

  const validateSMS = () => {
    // console.log("validateSMS");
    // 휴대폰번호의 길이 확인
    if (sms1.length !== 3 || sms2.length !== 4 || sms3.length !== 4) {
      modalInfo.mainMsg =
        "휴대폰 번호를 숫자(3자리)-숫자(4자리)-숫자(3자리)로 넣어주세요.";
      return false;
    } else {
      // 숫자인지 확인
      const hasNumber1 = !isNaN(sms1);
      const hasNumber2 = !isNaN(sms2);
      const hasNumber3 = !isNaN(sms3);
      // console.log(hasNumber1);
      // console.log(hasNumber2);
      // console.log(hasNumber3);
      if (hasNumber1 & hasNumber2 & hasNumber3) {
        // sms = sms1+"-"+sms2+"-"+sms3;
        return true;
      } else {
        modalInfo.mainMsg =
          "휴대폰 번호를 숫자(3자리)-숫자(4자리)-숫자(3자리)로 넣어주세요.";
        return false;
      }
    }
  };

  const getSMSAuthNo = async () => {
    if (verifyId) {
      if (verifySamePassword) {
        if (verifyEmail) {
          const validSMS = validateSMS();
          if (validSMS) {
            const sms = sms1 + "-" + sms2 + "-" + sms3;
            // console.log("SMS : "+sms)
            const data = { userId: id, mobile: sms };
            // console.log("data : "+JSON.stringify(data));
            const ret = await axios.post(
              `${Config.API_SERVER}user/sendSMS`,
              data
            );
            // console.log(JSON.stringify(ret));
            if (ret?.data?.data?.mobileAuthNo) {
              setMobileAuthNo(ret.data.data.mobileAuthNo);
              setModalInfo({
                ...modalInfo,
                mainMsg: "인증번호를 SMS로 확인하고 넣어주세요.",
              });
              setModalOpen(true);
            } else {
              // API 에러...
              modalInfo.actionFlag = "api";
              openModal();
            }
          } else {
            console.log("SMS Error");
            setModalInfo({ ...modalInfo });
            setModalOpen(true);
          }
        } else {
          modalInfo.actionFlag = "verifyEmail";
          openModal();
        }
      } else {
        modalInfo.actionFlag = "verifySamePassword";
        openModal();
      }
    } else {
      modalInfo.actionFlag = "verifyId";
      openModal();
    }
  };

  const validateSMSAuthNo = async () => {
    // console.log("validateSMSAuthNo");
    if (mobileAuthNo > 0) {
      const data = { userId: id, mobileAuthNo };
      // console.log("data : "+JSON.stringify(data));
      const ret = await axios.post(`${Config.API_SERVER}user/verifySMS`, data);
      // console.log(JSON.stringify(ret));
      if (ret.data.data.verifyResult) {
        setVerifySMS(true);
        setModalInfo({
          ...modalInfo,
          mainMsg: "휴대폰인증번호가 확인 되었습니다.",
        });
        setModalOpen(true);
      } else {
        // API 에러...
        modalInfo.actionFlag = "verifySMSError";
        openModal();
      }
    } else {
      modalInfo.actionFlag = "getSMSAuth";
      openModal();
    }
  };

  // 체크박스 클릭 핸들러
  const handleCheckbox = (value) => {
    setAcceptAgree(value);
    // console.log(value); // 선택된 값을 콘솔에 출력
  };

  const checkUserData = () => {
    // console.log("checkUserData");

    if (!id) {
      modalInfo.mainMsg = "아이디를 입력해 주세요.";
      return false;
    }
    if (!verifyId) {
      modalInfo.mainMsg = "아이디 중복확인 해주세요.";
      return false;
    }
    if (!password) {
      modalInfo.mainMsg = "비밀번호를 입력해 주세요.";
      return false;
    }
    if (!verifySamePassword) {
      modalInfo.mainMsg = "비밀번호 확인 해주세요.";
      return false;
    }
    if (!name) {
      modalInfo.mainMsg = "이름를 입력해 주세요.";
      return false;
    }
    if (!birthday) {
      modalInfo.mainMsg = "생년월일을 입력해 주세요.";
      return false;
    }
    // if(!gender)
    // {
    //     modalInfo.mainMsg = '성별을 반드시 선택하세요!';
    //     return false;
    // }
    if (!verifyEmail) {
      modalInfo.mainMsg = "이메일 인증번호를 받고 인증하기를 하세요.";
      return false;
    }
    if (!verifySMS) {
      modalInfo.mainMsg = "휴대폰번호 인증번호를 받고 인증하기를 하세요.";
      return false;
    }
    // if(!recvAgree)
    // {
    //     modalInfo.mainMsg = 'SMS/푸시/이메일 수신동의를 반드시 선택하세요!';
    //     return false;
    // }

    return true;
  };

  const goStore = async () => {
    try {
      if (checkUserData() === true) {
        // console.log(" true ");

        // user authToken 가져오기
        const authData = { userId: id, pwd: password };
        // console.log("authData : "+JSON.stringify(authData));
        const ret = await axios.post(
          `${Config.API_SERVER}auth/getUser`,
          authData
        );
        // console.log("Data : "+JSON.stringify(ret.data));
        // console.log("Data : "+JSON.stringify(ret.data.data.accessToken));

        if (ret.data.data.accessToken) {
          // userData.userId = id;
          // console.log(password);
          // console.log(name);
          // console.log(birthday);
          // console.log(gender);
          // console.log(email);
          // console.log(sms);
          // console.log(zipCode);
          // console.log(addr);
          // console.log(detailAddr);
          // console.log(acceptAgree);
          // console.log(document.getElementById('postcode').value);
          // console.log(document.getElementById("roadAddress").value);

          const token = ret.data.data.accessToken;
          console.log(token);
          userData.pwd = password;
          userData.userName = name;
          userData.birthday = birthday;
          userData.gender = gender;
          userData.email = email;
          userData.mobile = sms1 + "-" + sms2 + "-" + sms3;
          // if(email.length > 0) {
          //     userData.email = email;
          // }
          // console.log(sms);
          // if(sms.length > 0){
          //     userData.mobile = sms;
          // }
          if (document.getElementById("postcode").value > 0) {
            userData.zipCode = document.getElementById("postcode").value;
            totalUserData.zipCode = document.getElementById("postcode").value;
            userData.addr = document.getElementById("roadAddress").value;
            totalUserData.addr = document.getElementById("roadAddress").value;
            totalUserData.jibun = document.getElementById("jibunAddress").value;
            totalUserData.extra = document.getElementById("extraAddress").value;
          }
          if (detailAddr.length > 0) {
            userData.detAddr = detailAddr;
            totalUserData.detailAddr = detailAddr;
          }
          userData.acceptAgree = acceptAgree;

          // console.log(" userData : "+JSON.stringify(userData));

          // 관리자 정보 저장
          // const ret1 = await axios.put(`${Config.API_SERVER}user`,
          //     userData,
          //     { headers: {
          //         Authorization: "Bearer "+token,
          //         },
          //     }
          // );
          // console.log("Data : "+JSON.stringify(ret1.data));

          // 다음페이지로
          totalUserData.token = token;
          totalUserData.id = id;
          totalUserData.name = name;
          totalUserData.email = email;
          totalUserData.mobile = sms1 + "-" + sms2 + "-" + sms3;
          totalUserData.sms1 = sms1;
          totalUserData.sms2 = sms2;
          totalUserData.sms3 = sms3;

          // const totalUserData = {
          //             id : 'caffeL4',
          //             name: '카페엘 관리자4',
          //             token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTm8iOjEsIm1lbWJlck5vIjowLCJpYXQiOjE3MTExOTg5NjEsImV4cCI6MTcxMTIwMjU2MX0.C8EJPpxABeRKNmIiMhA76PjrPdtQZrLcMLJW7QVfzDU',
          //             sms1: '010',
          //             sms2: '2222',
          //             sms3: '3333',
          //             // sms1: '',
          //             // sms2: '',
          //             // sms3: '',
          //             zipCode: '12345',
          //             addr: '벚꽃로 278',
          //             detailAddr: '6층',
          //             jibun: '가산 벚꽃로 278',
          //             extra: '78',
          //         }
          // totalUserData.userData = userData;

          // console.log("totalUserData : "+JSON.stringify(totalUserData));
          console.log(totalUserData);

          navigate("/store-content", { state: { totalUserData } });
        }
      } else {
        setModalInfo({ ...modalInfo });
        setModalOpen(true);
      }
    } catch (error) {
      // 에러 발생 시의 코드
      // console.error("Error fetching data : ", JSON.stringify(error));
      if (error.response) {
        modalInfo.actionFlag = "api";
        openModal();
      } else if (error.request) {
        // 요청은 전송되었지만 응답이 없는 경우
        // alert("No response received from the server.");
        modalInfo.actionFlag = "api";
        openModal();
      } else {
        // 요청을 설정하는 중에 에러가 발생한 경우
        // alert("Error setting up the request.");
        modalInfo.actionFlag = "api";
        openModal();
      }
      console.error("Error fetching data:", error);
    }
  };

  const cancelUser = async () => {
    try {
      // console.log("id : ["+id+"]");
      if (id.length > 0) {
        const cancelData = { userId: id };
        const ret = await axios.put(`${Config.API_SERVER}user/pro`, cancelData);
      }

      navigate("/");
    } catch (error) {
      // 에러 발생 시의 코드
      // console.error("Error fetching data : ", JSON.stringify(error));
      if (error.response) {
        modalInfo.actionFlag = "api";
        openModal();
      } else if (error.request) {
        // 요청은 전송되었지만 응답이 없는 경우
        // alert("No response received from the server.");
        modalInfo.actionFlag = "api";
        openModal();
      } else {
        // 요청을 설정하는 중에 에러가 발생한 경우
        // alert("Error setting up the request.");
        modalInfo.actionFlag = "api";
        openModal();
      }
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <AlertModal
        open={modalOpen}
        close={closeModal}
        action={doModal}
        header={modalInfo.headerMsg}
        isConfirm={modalInfo.isConfirm}
        isMessage={modalInfo.isMessage}
        isCancel={modalInfo.isCancel}
      >
        {modalInfo.mainMsg}
      </AlertModal>

      <section className="location_box">
        <ul className="location_001">
          <li>홈</li>
          <li>회원가입</li>
          <li>01.관리자 정보</li>
        </ul>
      </section>

      <main>
        <section className="contants_box contants_box_02">
          <h2 className="tit_01">회원가입</h2>

          <article className="signup_03">
            <h3 className="tit_02">01.관리자 정보</h3>
            <p>내기프트 서비스 이용을 위해 관리자 정보를 입력 해 주세요.</p>
          </article>

          <article className="contants_box_03">
            <table className="member_box">
              <tbody>
                <tr>
                  <th>아이디</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                        placeholder="영문 소문자, 숫자를 포함한 5~15자 이내"
                        maxLength={15}
                        onChange={(e) => {
                          // setId({ ...id, searchWord: e.target.value });
                          setId(e.target.value);
                        }}
                        value={id}
                        onBlur={validateId}
                      />
                      <button
                        type="button"
                        className="button_05 button_gray"
                        onClick={idCkeck}
                      >
                        중복확인
                      </button>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="password"
                        name=""
                        placeholder="영문, 숫자, 특수문자 조합 8~16자"
                        maxLength={16}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                        onBlur={validatePassword}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>비밀번호 확인</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="password"
                        name=""
                        placeholder="영문, 숫자, 특수문자 조합 8~16자"
                        maxLength={16}
                        onBlur={(e) => {
                          // setId({ ...id, searchWord: e.target.value });
                          validateSamePassword(e.target.value);
                        }}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                        maxLength={80}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>생년월일</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="date"
                        name=""
                        onChange={(e) => {
                          setBirthday(e.target.value);
                        }}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>성별</th>
                  <td>
                    <form>
                      <select
                        className="input_01"
                        name="gender"
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        value={gender}
                      >
                        {/* <option value="" disabled>선택</option> */}
                        <option value="M">남자</option>
                        <option value="F">여자</option>
                      </select>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                        placeholder="예)naegift@authrium.com"
                        maxLength={80}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        onBlur={(e) => {
                          validateEmail(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="button_05 button_gray"
                        onClick={getEmailAuthNo}
                      >
                        인증번호받기
                      </button>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>이메일 인증번호</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                      />
                      <button
                        type="button"
                        className="button_05 button_gray"
                        onClick={validateEmailAuthNo}
                      >
                        인증하기
                      </button>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>휴대폰번호</th>
                  <td>
                    <form>
                      <input
                        className="input_02"
                        type="text"
                        name=""
                        maxLength={3}
                        onChange={(e) => {
                          setSms1(e.target.value);
                        }}
                      />
                      -
                      <input
                        className="input_02"
                        type="text"
                        name=""
                        maxLength={4}
                        onChange={(e) => {
                          setSms2(e.target.value);
                        }}
                      />
                      -
                      <input
                        className="input_02"
                        type="text"
                        name=""
                        maxLength={4}
                        onChange={(e) => {
                          setSms3(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="button_05 button_gray"
                        onClick={getSMSAuthNo}
                      >
                        인증번호받기
                      </button>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>휴대폰번호 인증번호</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                      />
                      <button
                        type="button"
                        className="button_05 button_gray"
                        onClick={validateSMSAuthNo}
                      >
                        인증하기
                      </button>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    <div className="signup_04">
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          id="postcode"
                          disabled
                          placeholder="우편번호"
                          // onChange={e => {
                          //     setzipCode(e.target.value);
                          //     }}
                        />
                        <button
                          type="button"
                          className="button_05 button_gray"
                          onClick={getPostcode}
                        >
                          찾기
                        </button>
                        <br />
                        <input
                          className="input_06"
                          type="text"
                          id="roadAddress"
                          disabled
                          placeholder="도로명주소"
                          // onChange={e => {
                          //     setAddr(e.target.value);
                          //     }}
                        />
                        &ensp;
                        <input
                          className="input_06"
                          type="text"
                          id="jibunAddress"
                          disabled
                          placeholder="지번주소"
                        />
                        <br />
                        <span
                          id="guide"
                          style={{ color: "#999", display: "none" }}
                        ></span>
                        {/* <br /> */}
                        <input
                          className="input_06"
                          type="text"
                          id="detailAddress"
                          maxLength={64}
                          placeholder="상세주소"
                          onChange={(e) => {
                            setDetailAddr(e.target.value);
                          }}
                        />
                        &ensp;
                        <input
                          className="input_06"
                          type="text"
                          id="extraAddress"
                          disabled
                          placeholder="참고항목"
                        />
                      </form>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>SMS/푸시/이메일 수신동의</th>
                  <td>
                    <div className="signup_05">
                      <label className="check_box">
                        수신동의
                        <input
                          type="checkbox"
                          name="agreement"
                          value="RA01"
                          checked={acceptAgree === "RA01"}
                          onChange={() => handleCheckbox("RA01")}
                        />
                        <span className="check_radio"></span>
                      </label>
                      <label className="check_box">
                        수신거부
                        <input
                          type="checkbox"
                          name="agreement"
                          value="RA02"
                          checked={acceptAgree === "RA02"}
                          onChange={() => handleCheckbox("RA02")}
                        />
                        <span className="check_radio"></span>
                      </label>
                      <p className="font_gray">
                        ※ 내기프트 서비스를 이용하기 위해서는 SMS/푸시/이메일
                        수신을 동의하셔야 합니다.{" "}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>

          <article className="button_box_01">
            {/* <NavLink to='/'> */}
            <button
              type="button"
              className="button_01 button_03 button_gray_line"
              onClick={cancelUser}
            >
              취소
            </button>
            {/* </NavLink> */}
            {/* <NavLink to='/store-content' state={userData}> */}
            <button
              type="button"
              className="button_01 button_03 button_red"
              onClick={goStore}
            >
              다음
            </button>
            {/* </NavLink> */}
          </article>
        </section>
      </main>
    </>
  );
}
