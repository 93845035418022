import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
// import NaegiftKoLogo11 from "../images/naegift_ko_logo_1.png"
import { Config } from "../../common/config";

export default function UserSignUp(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        {/* <!--location:start--> */}
        <section classNameName="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>회원가입</li>
            </ul>
        </section>
        {/* <!--location:end--> */}

        {/* <!--contants:start--> */}
        <main>
            <section className="contants_box contants_box_02">
                <h2 className="tit_01">회원가입</h2>

                {/* <!----> */}
                <article className="contants_box_03">
                    <table className="member_box">
                        <tbody>
                            <tr>
                                <td>
                                    <label className="check_box">전체 약관에 동의합니다.
                                        <input type="checkbox" />
                                        <span className="check_check"></span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="signup_01">
                                        <label className="check_box"><span className="font_red">[필수]</span> 서비스이용약관
                                            <input type="checkbox" checked="checked" />
                                            <span className="check_check"></span>
                                        </label>
                                        <NavLink to='/user-agreement' classNameName="button_04 button_gray_line">전체보기</NavLink>
                                        {/* <a className="button_04 button_gray_line" href="agreement.html">전체보기</a> */}
                                    </div>
                                    <div className="signup_02 agreement_box">
                                        <iframe src={`${Config.SHOPADMIN_SERVER}agreement_wrap.html`}></iframe>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="signup_01">
                                        <label className="check_box"><span className="font_red">[필수]</span> 개인정보취급방침
                                            <input type="checkbox" />
                                            <span className="check_check"></span>
                                        </label>
                                        <NavLink to='/user-privacy' classNameName="button_04 button_gray_line">전체보기</NavLink>
                                        {/* <a className="button_04 button_gray_line" href="privacy.html">전체보기</a> */}
                                    </div>
                                    <div className="signup_02 agreement_box">
                                        <iframe src={`${Config.SHOPADMIN_SERVER}privacy_wrap.html`}></iframe>
                                        {/* <iframe src="privacy_wrap.html"></iframe> */}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </article>

                {/* <!--button:start--> */}
                <article className="button_box_01">
                    <NavLink to='/user-content'>
                        <button type="button" className="button_01 button_02 button_red">다음</button>
                    </NavLink>
                </article>
                {/* <!--button:end--> */}

            </section>
        </main>
        {/* <!--contants:end--> */}
    </>
    );
}