import React, { useEffect, useRef } from 'react';
// import NaegiftKoLogo11 from "../images/naegift_ko_logo_1.png"


export default function IdPwdConfirm(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>

    </>
    );
}