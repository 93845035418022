import { createSlice } from "@reduxjs/toolkit";

const initUserInfo1 = {
  userNo: '',
  id: '',
  jwtToken: '',
  name: '',
  role: 0,   // 어드민/관리자/개인
  storeNo: '',
  storeName: '',
  storeImageUrl: '',
};

// 이전에 저장된 상태가 있는 경우 브라우저 메모리에 해당 상태를 사용
const savedState = sessionStorage.getItem('user1');
const initialState = savedState ? JSON.parse(savedState) : initUserInfo1;

export const userInfoSlice1 = createSlice({
  name: "userInfo1",
  initialState: { ...initUserInfo1 },
  reducers: {
    userLogin1: (state, action) => {
      return { ...action.payload };
    },
    userLogout1: (state) => {
      return { ...initUserInfo1 };
    },
  },
});

export const { userLogin1, userLogout1 } = userInfoSlice1.actions;

export default userInfoSlice1.reducer;