import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Config } from "../../common/config";
import axios from "axios";
import AlertModal from '../../components/alertModal';

export default function PwdSearch(props) {
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const openModal = (e) => {
        // if(modalInfo.actionFlag === 'mail'){
        //     setModalInfo({...modalInfo, mainMsg:'메일이 발송되었습니다.'});
        // }

        setModalOpen(true);
     };
     
     const closeModal = () => {
         setModalOpen(false);
        //  if(modalInfo.actionFlag === 'list'){
        //    getData();   
        // } 
     };
 
     const doModal = () => {
        // console.log("doModal actionFlag:"+modalInfo.actionFlag);
        setModalOpen(false);
        // if(modalInfo.actionFlag === 'mail'){
        //     navigate('/login-idsearch-done');
        // }
     };

     const doModModal = () => {
        // console.log("doModModal");
        // console.log("selectedId : "+selectedId);
        // modUser();
     };

    const checkUserData = () => {
        // console.log("checkUserData");

        if(!id)
        {
            modalInfo.mainMsg = '사용자 아이디를 입력하세요.';
            return false;
        }
        if(!name)
        {
            modalInfo.mainMsg = '이름를 입력하세요.';
            return false;
        }
        if(!email){
           modalInfo.mainMsg = '이메일 주소를 입력하세요.';
            return false;
        }
        
        return true;      
    }

    const validateEmail = () => {
        // 빈 문자열 검사
        if (!email) {
            modalInfo.mainMsg = '이메일 주소를 입력하세요.';
            return false;
        }
      
        // 문자열 형식 검사
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    const pwdSearch  = async() => {
        try{
            if (checkUserData() === true) {
                // console.log(" true ");
                if (validateEmail() === true) {
                    // 아이디 찾기
                    const userData = {userId: id, userName: name, email};
                    // console.log(JSON.stringify(userData));
                    modalInfo.mainMsg = '비밀번호를 재발급하여 메일을 발송중입니다.....';
                    modalInfo.isConfirm = false;
                    openModal();
                    const ret = await axios.post(`${Config.API_SERVER}user/searchPwd`,
                        userData,               
                    );
                    console.log("Data : "+JSON.stringify(ret.data));

                    if(ret?.data){
                        navigate('/login-pwdsearch-done');
                    }
                }else{
                    modalInfo.mainMsg = '이메일 주소가 유효하지 않습니다.';
                    setModalOpen(true);
                }
            }else{
                // console.log("Error");
                setModalInfo({...modalInfo}); 
                setModalOpen(true);
            }            
        } catch (error) {
            // 에러 발생 시의 코드
            console.error("Error fetching data : ", JSON.stringify(error));
            closeModal();
            modalInfo.mainMsg = '입력하신 정보와 일치하는 아이디가 없습니다.';
            setModalOpen(true);
        } 
    }

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
                {modalInfo.mainMsg}
        </AlertModal>

        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>비밀번호 찾기</li>
            </ul>
        </section>

        <main>
            <section className="contants_box_04">

                <article className="signup_07">
                    <h2 className="tit_01">비밀번호 찾기</h2>
                    <small>비밀번호는 가입 시 입력하신 이메일을 통해 찾을 수 있습니다.</small>
                </article>

                <article className="contants_box_03">
                    <table className="login_box">
                        <tbody>
                            <tr>
                                <th>아이디</th>
                                <td><input className="input_03" type="text" name="" 
                                    onChange={e => {
                                        setId(e.target.value);
                                    }} 
                                    value = {id} />
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td><input className="input_03" type="text" name=""  
                                    onChange={e => {
                                            setName(e.target.value);
                                        }} 
                                        value = {name} />
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td><input className="input_03" type="text" name="" 
                                     onChange={e => {
                                        setEmail(e.target.value);
                                    }} 
                                    value = {email} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </article>

                <div className="signup_08">
                    <article className="button_box_01">
                        <NavLink to='/login'>
                            <button type="button" className="button_01 button_07 button_gray_line">로그인</button>
                        </NavLink>
                        {/* <NavLink to='/login-pwdsearch-done'> */}
                            <button type="button" className="button_01 button_07 button_red" onClick={pwdSearch}>비밀번호 재발급 받기</button>
                        {/* </NavLink> */}
                    </article>
                </div>

            </section>
        </main>
    </>
    );
}