import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';


export default function IdSearchDone(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>아이디 찾기</li>
            </ul>
        </section>

        <main>
            <section className="contants_box_04">

                <article className="signup_07">
                    <h2 className="tit_01">아이디 찾기</h2>
                </article>

                <article className="contants_box_03">
                    <div className="signup_07_001">회원정보상의 <span className="font_red">이메일주소로<br/>회원님의 아이디를 발송</span>하였습니다.</div>
                </article>

                <div className="signup_08">
                    <article className="button_box_01">
                        <NavLink to='/'>
                            <button type="button" className="button_01 button_07 button_gray_line">홈</button>
                        </NavLink>
                        <NavLink to='/login'>
                            <button type="button" className="button_01 button_07 button_red">로그인</button>
                        </NavLink>
                    </article>
                </div>

            </section>
        </main>
    </>
    );
}