import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Config } from "../../common/config";
import ng_main_01 from "../../images/ng_main_01.png"
import ng_main_02 from "../../images/ng_main_02.jpg"
import ng_main_03 from "../../images/ng_main_03.jpg"
import ng_main_04 from "../../images/ng_main_04.jpg"
import ng_main_05 from "../../images/ng_main_05.jpg"
import ng_main_06 from "../../images/ng_main_06.jpg"
import ng_main_07 from "../../images/ng_main_07.jpg"
import { useSelector } from "react-redux";


export default function Main(props) {
    const [revealed, setRevealed] = useState(false);
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);

    const reveal = () => {
      var reveals = document.querySelectorAll(".reveal");
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 200;
  
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    };
  
    useEffect(() => {
        if(userInfo.id) {
            //navigate('/login');
        }
        window.addEventListener("scroll", reveal);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
        return () => {
            window.removeEventListener("scroll", reveal);
        };
    }, []);
  
  
    return (
    <>
        {/* <!--메인상단이미지:start--> */}
        <section className="main_box_01">
            <div className="main_01_001 fadeinUp_01">
                <small>그 동안 스타벅스 기프트를 보면서 부러워만 하셨죠?</small>
                <h2>이젠 우리 상점만의<br/>기프트를 만들어 보세요</h2>
            </div>
            <img className="fadeinUp_02 main_box_02_img" src={ng_main_01} alt='ng_main_01' />			
        </section>
        {/* <!--메인상단이미지:end--> */}
    
        {/* <!--contants:start--> */}
        <main>
            <section className="contants_box contants_box_01">
    
                {/* <!----> */}
                <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                    <div className="main_02_001">
                        <h3>선물하기 QR코드 및<br/>모바일 페이지 제공</h3>
                        <p>회원가입만 하면 우리 상점만의 선물하기 QR코드 및<br/>모바일 페이지가 생깁니다!</p>
                    </div>
                    <div>
                        <img className="main_box_02_img" src={ng_main_02} alt='ng_main_02'/>
                    </div>
                </article>
    
                {/* <!----> */}
                <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                    <div className="main_02_001">
                        <h3>선물하기 QR코드를<br/>어디에나 홍보하기</h3>
                        <p>선물하기 QR코드를 오프라인 매장, 온라인 홈페이지,<br/>네이버카페, 블로그, 지도서비스, 각종 소셜 사이트 <br/>어디든 올리고 홍보하세요!</p>
                    </div>
                    <div>
                        <img className="main_box_02_img" src={ng_main_03} alt='ng_main_03'/>
                    </div>
                </article>
    
                {/* <!----> */}
                <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                    <div className="main_02_001">
                        <h3>어느누구나 선물하기<br/>QR코드 스캔 후 선물하기</h3>
                        <p>우리 상점을 아끼는 손님들은 선물하기 QR코드 스캔 후<br/>누구에게나 우리 상점의 기프트를 선물할 수 있습니다!</p>
                    </div>
                    <div>
                        <img className="main_box_02_img" src={ng_main_04} alt='ng_main_04'/>
                    </div>
                </article>
    
                {/* <!----> */}
                <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                    <div className="main_02_001">
                        <h3>손님의 기프트<br/>QR코드 스캔 후 상품제공</h3>
                        <p>선물받은 손님의 기프트 QR코드를 스캔해서 기프트를<br/>검증하고 검증성공시 상품을 제공하세요!</p>
                    </div>
                    <div>
                        <img className="main_box_02_img" src={ng_main_05} alt='ng_main_05' />
                    </div>
                </article>
    
                {/* <!----> */}
                <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                    <div className="main_02_001">
                        <h3>판매현황 실시간 확인하기</h3>
                        <p>기프트 판매현황을 실시간으로 볼 수 있고, 기프트를 <br/>구매한 손님들에게 환불 등을 할 수 있습니다!</p>
                    </div>
                    <div>
                        <img className="main_box_02_img" src={ng_main_06} alt='ng_main_06' />
                    </div>
                </article>
    
                {/* <!----> */}
                <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                    <div className="main_02_001">
                        <h3>기프트 판매대금 지급조건</h3>
                        <p>기프트 판매 대금은 에스크로가 적용되어 상점으로 즉시<br/>입금되지 않고 손님의 기프트 QR코드 스캔 후에<br/>지급 조건에 따라 입금됩니다.</p>
                        <small>입금일은 결제사 지급일에 따릅니다.<br/>기프트 유효기간 경과시에 자동입금됩니다.<br/>입금시 내기프트 서비스 이용료(10%)를 제하고 입금됩니다.</small>
                    </div>
                    <div>
                        <img className="main_box_02_img" src={ng_main_07} alt='ng_main_07' />
                    </div>
                </article>
            </section>
        </main>
        {/* <!--contants:end--> */}
    </>
    );
}