import React, { useEffect, useRef } from 'react';

import './css/layout.css';
import './css/common.css';
import './css/main.css';
import './css/member.css';
import './css/table.css';
import './css/modal.css';

import Header from './components/header';
import Router from "./common/router";
import Main from './pages/main/main'
import Footer from './components/footer';


function App() {

  useEffect(() => {
    document.title = "내기프트";  
  }, []);    


  return (
    <>
      <Header />
      <Router />
      {/* <Main /> */}
      <Footer />
    </>   
  );
}

export default App;
