import { createSlice } from "@reduxjs/toolkit";

const initUserInfo = {
  userNo: '',
  id: '',
  pwd: '',
  jwtToken: '',
  name: '',
  role: 0,   // 어드민/관리자/개인
  storeNo: '',
  storeName: '',
  storeImageUrl: '',
};

// 이전에 저장된 상태가 있는 경우 브라우저 메모리에 해당 상태를 사용
const savedState = sessionStorage.getItem('user');
const initialState = savedState ? JSON.parse(savedState) : initUserInfo;

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    userLogin: (state, action) => {
      return { ...action.payload };
    },
    userLogout: (state) => {
      return { ...initUserInfo };
    },
    unsetToken: (state) => {
      state.jwtToken = '';
    },
    setToken: (state, action) => {
      state.jwtToken = action.payload.jwtToken;
    },
  },
});


export const { userLogin, userLogout, unsetToken, setToken } = userInfoSlice.actions;

export default userInfoSlice.reducer;