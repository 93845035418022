import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';


export default function PwdSearchDone(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>비밀번호 찾기</li>
            </ul>
        </section>

        <main>
            <section className="contants_box_04">

                <article className="signup_07">
                    <h2 className="tit_01">비밀번호 찾기</h2>
                </article>

                <article className="contants_box_03">
                    <div className="signup_07_001">회원정보상의 <span className="font_red">이메일주소로 회원님의 비밀번호를<br/>안전하게 재발급</span>하여 드립니다.<br/>임시비밀번호로 로그인 하신 후,<br/>회원정보에서 비밀번호를 변경해주세요.</div>
                </article>

                <div className="signup_08">
                    <article className="button_box_01">
                        <NavLink to='/'>
                            <button type="button" className="button_01 button_07 button_gray_line">홈</button>
                        </NavLink>
                        <NavLink to='/login'>
                            <button type="button" className="button_01 button_07 button_red">로그인</button>
                        </NavLink>
                    </article>
                </div>

            </section>
        </main>
    </>
    );
}