import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import QRCode from "qrcode.react";
import { Config } from "../../common/config";
import axios from "axios";
import AlertModal from "../../components/alertModal";
import { getPostcode } from "../../components/zip";
import { useSelector, useDispatch } from "react-redux";
import { userLogin1, userLogout1 } from "../../reducers/userInfo1";
import { translations } from '../../data/translation'; // Import the translations

export default function StoreContent(props) {
  const language = useSelector((state) => state.language.language); // Get current language from Redux store
  const t = translations[language]; // Get the translation object based on the selected language
  var script = document.createElement("script");
  script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  // script.src = Config.ADDRESS_SRC;
  document.head.appendChild(script);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  // const state = {
  //     totalUserData: {
  //         id : 'caffeL8',
  //         name: '카페엘 관리자8',
  //         email: 'hjlyu13579@gmail.com',
  //         mobile : '010-8756-8371',
  //         token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTm8iOjUzLCJtZW1iZXJObyI6MCwiaWF0IjoxNzEyMzIyMjkzLCJleHAiOjE3MTIzMjIzNTN9.6FSEozalJG8V2eb9j-ntEIougEP9X-3URC7WL6I9hJ0',
  //         sms1: '010',
  //         sms2: '8756',
  //         sms3: '8371',
  //         // sms1: '',
  //         // sms2: '',
  //         // sms3: '',
  //         zipCode: '08511',
  //         addr: '서울 금천구 벚꽃로 278 SJ테크노빌',
  //         detailAddr: '6층',
  //         jibun: '서울 금천구 벚꽃로 278 SJ테크노빌',
  //         extra: '6층',
  //     }
  // } ;
  // console.log("state : "+JSON.stringify(state));

  const [modalOpen, setModalOpen] = useState(false); //모달 상태 관리 : 기본값 - 닫힘
  const [modalInfo, setModalInfo] = useState({
    isCancel: false,
    isConfirm: true,
    isMessage: true,
    headerMsg: "",
    actionFlag: "",
    mainMsg: "",
    action: "",
    isPercent: false,
  });
  const [storeName, setStoreName] = useState("");
  const [id, setId] = useState(() => {
    if (state.totalUserData && state.totalUserData.id) {
      return state.totalUserData.id;
    } else {
      return "";
    }
  });
  const [name, setName] = useState(() => {
    if (state.totalUserData && state.totalUserData.name) {
      return state.totalUserData.name;
    } else {
      return "";
    }
  });
  const [email, setEmail] = useState(() => {
    if (state.totalUserData && state.totalUserData.email) {
      return state.totalUserData.email;
    } else {
      return "";
    }
  });
  const [mobile, setMobile] = useState(() => {
    if (state.totalUserData && state.totalUserData.mobile) {
      return state.totalUserData.mobile;
    } else {
      return "";
    }
  });
  const [token, setToken] = useState(() => {
    if (state.totalUserData && state.totalUserData.token) {
      return state.totalUserData.token;
    } else {
      return "";
    }
  });
  const [smsP1, setSmsP1] = useState(() => {
    if (state.totalUserData && state.totalUserData.sms1) {
      return state.totalUserData.sms1;
    } else {
      return "";
    }
  });
  const [smsP2, setSmsP2] = useState(() => {
    if (state.totalUserData && state.totalUserData.sms2) {
      return state.totalUserData.sms2;
    } else {
      return "";
    }
  });
  const [smsP3, setSmsP3] = useState(() => {
    if (state.totalUserData && state.totalUserData.sms3) {
      return state.totalUserData.sms3;
    } else {
      return "";
    }
  });
  const [zipCodeP, setzipCodeP] = useState(() => {
    if (state.totalUserData && state.totalUserData.zipCode) {
      return state.totalUserData.zipCode;
    } else {
      return "";
    }
  });
  const [addrP, setAddrP] = useState(() => {
    if (state.totalUserData && state.totalUserData.addr) {
      return state.totalUserData.addr;
    } else {
      return "";
    }
  });
  const [detailAddrP, setDetailAddrP] = useState(() => {
    if (state.totalUserData && state.totalUserData.detailAddr) {
      return state.totalUserData.detailAddr;
    } else {
      return "";
    }
  });
  const [jibunP, setJibunP] = useState(() => {
    if (state.totalUserData && state.totalUserData.jibun) {
      return state.totalUserData.jibun;
    } else {
      return "";
    }
  });
  const [extraP, setExtraP] = useState(() => {
    if (state.totalUserData && state.totalUserData.extra) {
      return state.totalUserData.extra;
    } else {
      return "";
    }
  });
  // console.log(id);
  // console.log(token);
  // console.log(sms1P);
  // console.log(sms2P);
  // console.log(sms3P);
  // console.log(zipCodeP);
  // console.log(addrP);
  // console.log(detailAddrP);
  // console.log(jibunP);
  // console.log(extraP);
  const [sms1, setSms1] = useState("");
  const [sms2, setSms2] = useState("");
  const [sms3, setSms3] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [addr, setAddr] = useState("");
  const [detailAddr, setDetailAddr] = useState("");
  const [jibun, setJibun] = useState("");
  const [extra, setExtra] = useState("");
  let phone = "";
  const [verifyPhone, setVerifyPhone] = useState(false);
  const [accountNo, setAccountNo] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  // const [imageFile, setImageFile] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [isAddrChecked, setIsAddrChecked] = useState(false);
  const [bankCode, setBankCode] = useState("B001");
  const [bankData, setBankData] = useState([]);
  // let storeNo = 0;
  const [storeNo, setStoreNo] = useState(0);
  const [qrValue, setQRValue] = useState("");
  // let qrValue = '';
  // const [storeData, setStoreData] =  useState({});
  // let passData = {};

  const openModal = (e) => {
    if (modalInfo.actionFlag === "accountNo") {
      setModalInfo({
        ...modalInfo,
        mainMsg: '입금받을 계좌번호는 "-" 없이 숫자만 넣어주세요.',
      });
    } else if (modalInfo.actionFlag === "api") {
      setModalInfo({
        ...modalInfo,
        mainMsg: "API 서버에러입니다. 잠시 후 다시 시도해주세요.",
      });
    } else if (modalInfo.actionFlag === "mail") {
      setModalInfo({
        ...modalInfo,
        mainMsg: `메일과 SMS를 보내는 중입니다.....`,
      });
    }

    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    //  if(modalInfo.actionFlag === 'list'){
    //    getData();
    // }
  };

  const doModal = () => {
    // console.log("doModal actionFlag:"+modalInfo.actionFlag);
    setModalOpen(false);
    // if(modalInfo.actionFlag === 'mod'){
    //     doModModal();
    // }else if(modalInfo.actionFlag === 'pwd'){
    //     // 바뀐 비밀번호값 넣기
    //     modalInfo.isMessage = true;
    // }
  };

  const doModModal = () => {
    // console.log("doModModal");
    // console.log("selectedId : "+selectedId);
    // modUser();
  };

  const validatePhone = () => {
    // console.log("validatePhone");
    // 휴대폰번호의 길이 확인
    if (sms1.length !== 3 || sms2.length !== 4 || sms3.length !== 4) {
      modalInfo.mainMsg =
        "휴대폰 번호를 숫자(3자리)-숫자(4자리)-숫자(3자리)로 넣어주세요!";
      return false;
    } else {
      // 숫자인지 확인
      const hasNumber1 = !isNaN(sms1);
      const hasNumber2 = !isNaN(sms2);
      const hasNumber3 = !isNaN(sms3);
      // console.log(hasNumber1);
      // console.log(hasNumber2);
      // console.log(hasNumber3);
      if (hasNumber1 & hasNumber2 & hasNumber3) {
        phone = sms1 + "-" + sms2 + "-" + sms3;
        return true;
      } else {
        modalInfo.mainMsg =
          "휴대폰 번호를 숫자(3자리)-숫자(4자리)-숫자(3자리)로 넣어주세요!";
        return false;
      }
    }
  };

  const phoneCheckboxChange = (e) => {
    setIsPhoneChecked(!isPhoneChecked);

    if (e.target.checked) {
      // console.log(sms1);
      // console.log(sms2);
      // console.log(sms3);
      if (smsP1) {
        setSms1(smsP1);
        setSms2(smsP2);
        setSms3(smsP3);
      }
    } else {
      setSms1("");
      setSms2("");
      setSms3("");
    }
  };

  const addrCheckboxChange = (e) => {
    setIsAddrChecked(!isAddrChecked);
    if (e.target.checked) {
      if (zipCodeP) {
        setzipCode(zipCodeP);
        setAddr(addrP);
        setDetailAddr(detailAddrP);
        setJibun(jibunP);
        setExtra(extraP);
      }
    } else {
      setzipCode("");
      setAddr("");
      setDetailAddr("");
      setJibun("");
      setExtra("");
    }
  };

  const setAddress = (e) => {
    // console.log(e.target.value);
    if (document.getElementById("postcode").value > 0) {
      setzipCode(document.getElementById("postcode").value);
      setAddr(document.getElementById("roadAddress").value);
      setJibun(document.getElementById("jibunAddress").value);
      setExtra(document.getElementById("extraAddress").value);
    }
    setDetailAddr(e.target.value);
  };

  const bankChange = (e) => {
    setBankCode(e.target.value);
    // console.log('Selected Bank:', e.target.value);
  };

  async function getData() {
    try {
      const ret = await axios.get(
        `${Config.API_SERVER}code/detail` +
          "?pageSize=20&sortOrd=asc&groupCodeName=은행",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (ret?.data?.data) {
        // const count = ret.data.data.totalCount;
        // setBankData(JSON.stringify(ret.data.data.list));
        setBankData(ret.data.data.list);
      }
    } catch (error) {
      console.error("Error getData:", error);
    }
  }

  const validateAccountNo = () => {
    // console.log("validateAccountNo");
    // 숫자인지 확인
    const hasNumber = !isNaN(accountNo);
    // console.log(hasNumber);
    if (hasNumber) {
      return true;
    } else {
      modalInfo.actionFlag = "accountNo";
      openModal();
    }
  };

  const checkStoreData = () => {
    // console.log("checkStoreData");

    if (!storeName) {
      modalInfo.mainMsg = "상점명을 입력해 주세요.";
      return false;
    }
    const validPhone = validatePhone();
    if (!validPhone) return false;
    if (!phone) {
      modalInfo.mainMsg = "전화번호를 입력해 주세요.";
      return false;
    }
    if (!zipCode) {
      modalInfo.mainMsg = "주소를 입력해 주세요.";
      return false;
    }
    if (!bankCode) {
      modalInfo.mainMsg = "입금받을 은행을 선택해 주세요.";
      return false;
    }
    if (!accountNo) {
      modalInfo.mainMsg = "입금받을 계좌번호를 입력해 주세요.";
      return false;
    }
    if (!accountHolder) {
      modalInfo.mainMsg = "예금주를 입력해 주세요.";
      return false;
    }

    return true;
  };

  const makeStore = async () => {
    const validPhone = validatePhone();
    try {
      const formData = new FormData();
      formData.append("storeName", storeName);
      formData.append("phone", phone);
      formData.append("zipCode", zipCode);
      formData.append("addr", addr);
      formData.append("detAddr", detailAddr);
      formData.append("bankCode", bankCode);
      formData.append("accountNo", accountNo);
      formData.append("accountHolder", accountHolder);

      // 이미지 파일 추가
      if (imageFile) {
        // console.log("file");
        formData.append("file", imageFile);
      } else {
        // console.log("No file");
      }
      // console.log(" formData : "+JSON.stringify(formData));
      for (const item of formData.entries()) {
        console.log(item[0] + ": " + item[1]);
      }

      // 상점 정보 저장
      const ret = await axios.post(`${Config.API_SERVER}store`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      // console.log("Data : "+JSON.stringify(ret.data));

      if (ret?.data?.data?.storeNo) {
        // console.log(ret?.data?.data?.storeNo);
        // storeNo = ret?.data?.data?.storeNo;
        setStoreNo(ret?.data?.data?.storeNo);
        setQRValue(ret?.data?.data?.qrValue);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // 에러 발생 시의 코드
      console.error("Error fetching data : ", JSON.stringify(error));
      return false;
    }
  };

  const makeQRCode = async () => {
    // console.log("makeQRCode");
    if (checkStoreData() === true) {
      // console.log(" checkStoreData true ");
      const ret = await makeStore();
      if (ret === true) {
        // console.log(" makeStore true ");
      } else {
        // console.log(" makeStore false ");
        modalInfo.actionFlag = "api";
        openModal();
      }
    } else {
      setModalInfo({ ...modalInfo });
      setModalOpen(true);
    }
  };

  const goRegDone = async () => {
    // console.log("storeNo : "+ storeNo);
    // console.log("qrValue : "+ qrValue);
    if (storeNo > 0) {
      goConfirm();
    } else {
      if (checkStoreData() === true) {
        // console.log(" checkStoreData true ");
        const ret = await makeStore();
        if (ret === true) {
          // console.log(" makeStore true ");
          // console.log("storeNo : "+ storeNo);
          // console.log("qrValue : "+ qrValue);

          goConfirm();
        } else {
          modalInfo.actionFlag = "api";
          openModal();
        }
      } else {
        setModalInfo({ ...modalInfo });
        setModalOpen(true);
      }
    }
  };

  const goConfirm = async () => {
    try {
      modalInfo.actionFlag = "mail";
      modalInfo.isConfirm = false;
      openModal();
      const sendData = { userId: id, userName: name, email, mobile };
      // console.log(JSON.stringify(sendData));

      const ret = await axios.post(
        `${Config.API_SERVER}user/sendMessage`,
        sendData
      );
      // console.log("Data : "+JSON.stringify(ret.data.data));

      const user = {
        id: id,
        name: name,
        // jwtToken: token,
        // storeNo,
      };

      // console.log("===== user : "+JSON.stringify(user));

      dispatch(userLogin1(user));
      navigate("/user-confirm");

      // navigate('/user-confirm', {state: user});
    } catch (error) {
      // 에러 발생 시의 코드
      // console.error("Error fetching data : ", JSON.stringify(error));
      if (error.response) {
        modalInfo.actionFlag = "api";
        openModal();
      } else if (error.request) {
        // 요청은 전송되었지만 응답이 없는 경우
        // alert("No response received from the server.");
        modalInfo.actionFlag = "api";
        openModal();
      } else {
        // 요청을 설정하는 중에 에러가 발생한 경우
        // alert("Error setting up the request.");
        modalInfo.actionFlag = "api";
        openModal();
      }
      console.error("Error fetching data:", error);
    }
  };

  const changeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        // 3MB 이상인 경우
        e.target.value = null; // 파일 선택 취소
        modalInfo.mainMsg = "이미지 파일 크기는 3MByte를 초과할 수 없습니다.";
        setModalOpen(true);
      } else {
        setImageFile(URL.createObjectURL(file)); // 선택된 파일을 URL.createObjectURL을 사용하여 미리보기로 보여줌
      }
    } else {
      setImageFile(null);
    }
  };

  const cancelUser = async () => {
    try {
      // console.log("id : ["+id+"]");
      if (id.length > 0) {
        const cancelData = { userId: id };
        const ret = await axios.put(`${Config.API_SERVER}user/pro`, cancelData);
      }

      navigate("/");
    } catch (error) {
      // 에러 발생 시의 코드
      // console.error("Error fetching data : ", JSON.stringify(error));
      if (error.response) {
        modalInfo.actionFlag = "api";
        openModal();
      } else if (error.request) {
        // 요청은 전송되었지만 응답이 없는 경우
        // alert("No response received from the server.");
        modalInfo.actionFlag = "api";
        openModal();
      } else {
        // 요청을 설정하는 중에 에러가 발생한 경우
        // alert("Error setting up the request.");
        modalInfo.actionFlag = "api";
        openModal();
      }
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AlertModal
        open={modalOpen}
        close={closeModal}
        action={doModal}
        header={modalInfo.headerMsg}
        isConfirm={modalInfo.isConfirm}
        isMessage={modalInfo.isMessage}
        isCancel={modalInfo.isCancel}
      >
        {modalInfo.mainMsg}
      </AlertModal>

      <section className="location_box">
        <ul className="location_001">
        <li>{t.home}</li>
        <li>{t.signup}</li>
        <li>{t.adminInfo}</li>
        <li>{t.storeInfo}</li>
        </ul>
      </section>

      <main>
        <section className="contants_box contants_box_02">
        <h2 className="tit_01">{t.signup}</h2>

          <article className="signup_03">
          <h3 className="tit_02">{t.storeInfo}</h3>
            <p>
              {t.storeInfoDescription1}
              <br />
              {t.storeInfoDescription2}
            </p>
          </article>

          <article className="contants_box_03">
            <table className="member_box">
              <tbody>
                <tr>
                <th>{t.storeName}</th> 
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                        placeholder=""
                        maxLength={64}
                        onChange={(e) => {
                          setStoreName(e.target.value);
                        }}
                        value={storeName}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                <th>{t.storeLogo}</th>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <form>
                          <input
                            type="file"
                            name="fileInput"
                            onChange={changeFile}
                            accept=".jpg, .jpeg, .gif, .png"
                          />
                        </form>
                        <p className="font_gray">
                        {t.fileDescription}
                        </p>
                      </div>
                      <div style={{ width: "100px" }}></div>
                      <div>
                        {imageFile && (
                          <img
                            src={imageFile}
                            alt="Selected"
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                <th>{t.phoneNumber}</th>
                  <td>
                    <form>
                      <input
                        className="input_02"
                        type="text"
                        name=""
                        maxLength={3}
                        value={sms1}
                        onChange={(e) => {
                          setSms1(e.target.value);
                        }}
                      />
                      -
                      <input
                        className="input_02"
                        type="text"
                        name=""
                        maxLength={4}
                        value={sms2}
                        onChange={(e) => {
                          setSms2(e.target.value);
                        }}
                      />
                      -
                      <input
                        className="input_02"
                        type="text"
                        name=""
                        maxLength={4}
                        value={sms3}
                        onChange={(e) => {
                          setSms3(e.target.value);
                        }}
                      />
                      <label className="check_box">
                      {t.sameAsAdmin}
                        <input
                          type="checkbox"
                          checked={isPhoneChecked}
                          onChange={phoneCheckboxChange}
                        />
                        <span className="check_check"></span>
                      </label>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>{t.address}</th>
                  <td>
                    <div className="signup_04">
                      {/* <form>
                                            <input className="input_01" type="text" name="" disabled />
                                            <button type="button" className="button_05 button_gray">찾기</button>
                                            <br/>
                                            <input className="input_03" type="text" name="" disabled /><br/>
                                            <input className="input_03" type="text" name="" />
                                        </form> */}
                      <form>
                        <input
                          className="input_01"
                          type="text"
                          id="postcode"
                          disabled
                          placeholder={t.postalCode}
                          value={zipCode}
                          // onChange={e => {
                          //     setzipCode(e.target.value);
                          //     }}
                        />
                        <button
                          type="button"
                          className="button_05 button_gray"
                          onClick={getPostcode}
                        >
                          {t.searchAddress}
                        </button>
                        &ensp;
                        <label className="check_box">
                        {t.sameAsAdmin}
                          <input
                            type="checkbox"
                            checked={isAddrChecked}
                            onChange={addrCheckboxChange}
                          />
                          <span className="check_check"></span>
                        </label>
                        <br />
                        <input
                          className="input_06"
                          type="text"
                          id="roadAddress"
                          disabled
                          placeholder={t.roadAddress}
                          value={addr}
                          // onChange={e => {
                          //     setAddr(e.target.value);
                          //     }}
                        />
                        &ensp;
                        <input
                          className="input_06"
                          type="text"
                          id="jibunAddress"
                          disabled
                          placeholder={t.jibunAddress}
                          value={jibun}
                        />
                        <br />
                        <span
                          id="guide"
                          style={{ color: "#999", display: "none" }}
                        ></span>
                        {/* <br /> */}
                        <input
                          className="input_06"
                          type="text"
                          id="detailAddress"
                          maxLength={64}
                          placeholder={t.detailAddress}
                          value={detailAddr}
                          onChange={(e) => {
                            setAddress(e);
                            // setDetailAddr(e.target.value);
                          }}
                        />
                        &ensp;
                        <input
                          className="input_06"
                          type="text"
                          id="extraAddress"
                          disabled
                          placeholder={t.referenceItem}
                          value={extra}
                        />
                      </form>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{t.selectBank}</th>
                  <td>
                    <form>
                      <select
                        className="input_01"
                        onChange={bankChange}
                        value={bankCode}
                      >
                        {/* <option value=""  disabled>은행선택</option> */}
                        {bankData.map((bank, index) => (
                          <option
                            key={index}
                            value={bank.detailCode}
                          >
                            {bank.detailCodeName}
                          </option>
                        ))}
                      </select>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>{t.bankAccountNumber}</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                        placeholder={t.bankAccountinput}
                        maxLength={20}
                        onChange={(e) => {
                          setAccountNo(e.target.value);
                        }}
                        value={accountNo}
                        onBlur={validateAccountNo}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>{t.accountHolder}</th>
                  <td>
                    <form>
                      <input
                        className="input_01"
                        type="text"
                        name=""
                        maxLength={80}
                        onChange={(e) => {
                          setAccountHolder(e.target.value);
                        }}
                        value={accountHolder}
                      />
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>{t.giftQR}</th>
                  <td>
                    <div className="signup_06">
                      <div className="signup_06_002">
                        {qrValue !== "" && (
                          <>
                            <QRCode value={qrValue} />
                            {/* <input className="input_01" type="text" value = {qrValue} />
                                                <input className="input_01" type="text" value = {storeNo} /> */}
                          </>
                        )}
                      </div>
                      <div className="button-container">
                        <button
                          type="button"
                          className="button_05 button_blue"
                          onClick={(e) => {
                            makeQRCode();
                          }}
                        >
                         {t.createQR}
                        </button>
                        <button
                          type="button"
                          className="button_05 button_black"
                        >
                          {t.downloadQR}
                        </button>
                        <button
                          type="button"
                          className="button_05 button_gray"
                        >
                          {t.printQR}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>

          <article className="button_box_01">
            <NavLink to="/">
              <button
                type="button"
                className="button_01 button_03 button_gray_line"
                onClick={cancelUser}
              >
                {t.cancel}
              </button>
            </NavLink>
            <button
              type="button"
              className="button_01 button_03 button_red"
              onClick={goRegDone}
            >
             {t.signup}
            </button>
          </article>
        </section>
      </main>
    </>
  );
}
