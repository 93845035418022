import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogin, userLogout } from "../reducers/userInfo";
// import axios from "axios";
// import { Config } from "../common/config";

import naegift_ko_logo from "../images/naegift_ko_logo.png";

export default function Header() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();

  const logOut = () => {
    // 로그인 정보 존재할 때
    dispatch(userLogout());
    goLogout();
    // navigate('/')
  };
  const handleLoginClick = (e) => {
    e.preventDefault();
    window.location.href = "https://shopadmin-test.naegift.com/login";
  };

  const goLogout = async () => {
    try {
      // console.log("jwtToken : "+jwtToken);
      // let ret = await axios.post(`${Config.API_SERVER}logout`,
      //     { headers: {
      //         Authorization: "Bearer "+userInfo.jwtToken,
      //         },
      //     }
      // );
      // console.log("ret.data : "+JSON.stringify(ret.data));
    } catch (error) {
      console.error("Error User Logout", error);
    }
  };

  useEffect(() => {
    // console.log("header : "+JSON.stringify(userInfo));
  }, []);

  return (
    <>
      <header>
        <section className="header_box">
          <h1>
            <NavLink to="/">
              <img
                className="header_logo"
                src={naegift_ko_logo}
                alt="내기프트"
              />
            </NavLink>
          </h1>
          <nav>
            <ul className="header_nav">
              <li>
                <NavLink
                  className="select"
                  to="/"
                >
                  서비스소개
                </NavLink>
                {/* <a className="select" href="">서비스소개</a> */}
              </li>
              <li>
                <a href="">상점지원</a>
              </li>
            </ul>
          </nav>
          <ul className="header_login">
            {userInfo?.id ? (
              <>
                <li>
                  <NavLink
                    to="/"
                    onClick={logOut}
                  >
                    로그아웃
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/gift-list">마이페이지</NavLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink onClick={handleLoginClick}>로그인</NavLink>
                </li>
                <li>
                  <NavLink to="/user-signup">회원가입</NavLink>
                </li>
              </>
            )}
          </ul>
        </section>
      </header>
    </>
  );
}
