import React from 'react';
import { Config } from "../common/config";


function AlertModal(props) {

// 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
const { open, close, action, header, actionFlag, isCancel, isConfirm, isMessage, error } = props;
// console.log("AlertModal.props : "+JSON.stringify(props));

return (
    // 모달이 열릴때 openModal 클래스가 생성된다.

    <div className={open ? 'openModal modal' : 'modal'}>
    {open ? (
        <>
            {isMessage ? 
                    <section className="modal_popup_01">
                        {/* <a className="modal_popup_close" href="">&times;</a> */}
                        <div className="modal_popup_01_001"><pre>{props.children}</pre></div>
                        <article className="button_box_01">
                            {isCancel &&
                                <button type="button" className="button_04 button_gray_line" onClick={close}>취소</button>
                            }
                            {isConfirm &&
                                <button type="button" className="button_04 button_black" onClick={action}>확인</button>
                            }
                        </article>
                    </section>
            : 
                    <section className="modal_popup_02">
                        <a className="modal_popup_close" href="">&times;</a>
                        <h3 className="modal_popup_tit_01">비밀번호 변경하기</h3>
                        <article className="contants_box_03">
                            {props.children}
                        </article>

                        <article className="modal_popup_02_001">※ 사용 : 영문, 숫자, 특수문자 중 2가지 이상 10~16자<br/>
                            (사용 가능한 특수문자 : ! ” # $ % & ’ ( ) * + , - . / : ; &gt; = &lt; ?@ [ \ ] ^ _ ` '{' | '}' ~ )<br/>
                            ※ 불가 : 현재 비밀번호, 아이디, 동일한 글자 4번 이상, 휴대폰번호번호
                        </article>

                        <article className="button_box_01">
                            <button type="button" className="button_04 button_gray_line" onClick={close}>취소</button>
                            <button type="button" className="button_04 button_black" onClick={action}>확인</button>
                        </article>
                    </section>
            }
        </>
    ) : null}
    </div>

    )
}

function Example(props) {
    return (
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginTop: 10, display: "flex" }}>
        <div style={{ width: "50%", paddingRight: 0 }}>{props.children}</div>
        <div style={{ width: "50%" }}>
            <h3 classNameName="h5">&nbsp;{props.label}</h3>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    );
  }


export default AlertModal;