import React from 'react';
import { Route, Routes } from "react-router-dom";
import Main from '../pages/main/main.js';
import Login from '../pages/login/login.js';
import IdPwdConfirm from '../pages/login/idPwdConfirm.js';
import IdSearch from '../pages/login/idSearch.js';
import IdSearchDone from '../pages/login/idSearchDone.js';
import PwdSearch from '../pages/login/pwdSearch.js';
import PwdSearchDone from '../pages/login/pwdSearchDone.js';
import StoreContent from '../pages/store/storeContent.js';
import UserConfirm from '../pages/user/userConfirm.js';
import UserContent from '../pages/user/userContent.js';
import UserSignUp from '../pages/user/userSignUp.js';
import Agreement from '../pages/user/agreement.js';
import AgreementWrap from '../pages/user/agreementWrap.js';
import Privacy from '../pages/user/privacy.js';
import PrivacyWrap from '../pages/user/privacyWrap.js';
// import Test from '../pages/user/test.js';

// import GiftContent from '../pages/gift/giftContent.js';
// import GiftList from '../pages/gift/giftList.js';
// import GiftSales from '../pages/gift/giftSales.js';
// import QnaContent from '../pages/qna/qnaContent.js';
// import QnaList from '../pages/qna/qnaList.js';
// import QnaManage from '../pages/qna/qnaManage.js';
// import StoreManage from '../pages/store/storeManage.js';
// import UserManage from '../pages/user/userManage.js'
// import UserWithdrawal from '../pages/user/userWithdrawal.js';


export default function Router() {
  return (
    <Routes>
      {/* <Route path="/" element={<BasicExport />}/> */}
      {/* <Route path="/store/:storeId/member-registration" element={<StoreMemberRegistration />} /> */}
      <Route path="/" element={<Main />}/>
      {/* <Route path="/:storeId" element={<store />}/> */}
      <Route path="/login" element={<Login />}/>
      <Route path="/login-idsearch" element={<IdSearch />}/>
      <Route path="/login-idsearch-done" element={<IdSearchDone />}/>
      <Route path="/login-pwdsearch" element={<PwdSearch />}/>
      <Route path="/login-pwdsearch-done" element={<PwdSearchDone />}/>
      <Route path="/login-confirm" element={<IdPwdConfirm />}/>
      <Route path="/user-signup" element={<UserSignUp />}/>
      <Route path="/user-content" element={<UserContent />}/>
      {/* <Route path="/user-manage" element={<UserManage />}/> */}
      <Route path="/user-confirm" element={<UserConfirm />}/>
      {/* <Route path="/user-withdrawal" element={<UserWithdrawal />}/> */}
      <Route path="/user-agreement" element={<Agreement />}/>
      <Route path="/user-agreement-wrap" element={<AgreementWrap />}/>
      <Route path="/user-privacy" element={<Privacy />}/>
      <Route path="/user-privacy-wrap" element={<PrivacyWrap />}/>
      <Route path="/store-content" element={<StoreContent />}/>
      {/* <Route path="/test" element={<Test />}/> */}
      {/* <Route path="/store-manage" element={<StoreManage />}/> */}
      {/* <Route path="/gift-list" element={<GiftList />}/>
      <Route path="/gift-content" element={<GiftContent />}/>
      <Route path="/gift-sales" element={<GiftSales />}/>
      <Route path="/qna-list" element={<QnaList />}/>
      <Route path="/qna-content" element={<QnaContent />}/>
      <Route path="/qna-manage" element={<QnaManage />}/> */}
    </Routes>
  );
}